import React, { useState } from "react";

export default function ToolTip({ onSave }) {



   return (
    <div id="tooltip">

          <button onClick={onSave} className="button-30" >Save</button>



     </div>
  );
}
