import { useState } from "react";
import { useEffect } from "react";
//import Stripe from 'stripe';
import {auth} from "../db/firebase";

import { deleteUserData } from "../db/database";



export default function Suscription({ email,data }) {



 const  [ susId, setsusIdState ] = useState(null);
 const  [ cancel, setCancel ] = useState();
 const  [ issuccess, setIssuccess ] = useState(false);
 const  [ iserror, setIserror ] = useState(false);




 //const stripe = new Stripe('pk_live_51P7LzRDeQQ3MUrpaYT2V4jFqfbt5vlr1HvqCjcbNs1BXZVmwJ1Xr9zGwe29rS6HgE4982ve42ckXUWXd4PZZ0peo00CmI8YTsz');

  useEffect( ()=>{


          /**       async function fetchData() {
          // You can await here
        //  const response = await MyAPI.getData(someId);
         console.log("stripe email",email);
          const customers = await stripe.customers.search({
            query: `email:\'${email}\'`
          });
            // console.log("suscription",customers.data[0].id);
             return customers.data[0]?.id;

            }

        async function fetchSusId() {

                const cusId  = await fetchData();
                if( cusId !== undefined ){
              const invoices = await stripe.invoices.search({
                          query: `customer:\'${cusId}\'`,
                        });

            console.log("susif",invoices.data[0]?.lines.data[0]?.subscription);

               setsusIdState(invoices.data[0]?.lines.data[0]?.subscription);
               return invoices.data[0]?.lines.data[0]?.subscription ?? null;

                } else {

                  return null;

                }


            }

            fetchSusId();

          console.log("SusId++++",susId);

       **/


  },[susId]);

  const handleCancel = async (id) => {

  /**  console.log("SusId++++",susId);
    const subscription = await stripe.subscriptions.cancel(
                                    `${id}`
                                  ); */

    const requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify({ email:email  })
   };

   const resapi = await fetch('http://localhost:8080/api/cancel', requestOptions)
       .then(response => response.json());

           console.log("api backend res:",resapi);

               if(resapi){

                const user = auth.currentUser;
                    deleteUserData(user.email);
                          user.delete().then(() => {
                                    console.log("user deleted  ====")   ;                       // User deleted.
                            }).catch((error) => {
                                                              // An error occurred
                                                              // ...
                          }); }

                          return resapi ?? false;



  };

  return (

    <main class="ml-60 pt-16 max-h-screen overflow-auto">
    <div class="px-6 py-8">
      <div class="max-w-4xl mx-auto">


<div class="relative overflow-x-auto">
    <table class=" tbl w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400">
        <thead class="text-xs text-black-700 uppercase bg-white-50 dark:bg-white-700 dark:text-black-400 ">
            <tr class ="border-gray-700 ">
                <th scope="col" class="px-6 py-3">
                  Suscription
                </th>

            </tr>
        </thead>
        <tbody class="tbl">
            <tr class="bg-white border-b dark:bg-white-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-black-900 whitespace-nowrap dark:text-black">
                    Customer
                </th>
                <td class="px-6 py-4">
                    {data.name}
                </td>
                <td class="px-6 py-4">
                    Email
                </td>
                <td class="px-6 py-4">
                    {email}
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-white-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-black-900 whitespace-nowrap dark:text-black">
                  Plan
                </th>
                <td class="px-6 py-4">
                    Suscription
                </td>
                <td class="px-6 py-4">
                    price
                </td>
                <td class="px-6 py-4">
                { data.free ? 'free': '$3' }
                </td>
            </tr>
            <tr class="bg-white dark:bg-white-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-black-900 whitespace-nowrap dark:text-black">
                    Billing Period
                </th>
                <td class="px-6 py-4">
                    30 days
                </td>
                <td class="px-6 py-4">
                    Status
                </td>
                <td class="px-6 py-4">
                    { data.free ? 'free': 'active' }
                </td>
            </tr>
        </tbody>
    </table>

   { !data.free &&
     <button onClick={()=>{setCancel(true);
         console.log(cancel);
     }} type="button" class="mt-12 bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded" >Cancel Suscription</button>

       }

    { true && cancel ? <button  onClick={ async ()=>{
      try{
       const success =  await handleCancel(susId);
       console.log("return api---",success);
        if(success){

          setIssuccess(success);

        }else{

           setIserror(true);


        }
        setCancel(false);

      }catch(e){
           setIserror(true);
      }


    } } type="button" class="mt-12 ml-8 bg-red-700 hover:bg-blue-700 text-white  py-2 px-4 rounded " >Click To Confirm</button>

    : ''

    }

    {issuccess && <div class="mt-10">
     <div  class="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Your Suscription have been cancel successfully </div>
    </div>}

    {iserror && <div class="mt-10">
      <div  class="block w-full rounded-md bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Oup, sorry something when wrong</div>
      <p class="mt-8">If the error persists, proceed to the <a class="supportmanagment"  href="https://pay.boomfi.xyz/2jqO1xzlJtk3QeKfvmBCM9rKwjy">checkout form</a>. Enter your credentials, including name and email, confirm, and click on "Manage Subscription."
      From there, you can cancel your subscription or for a more direct approach <a class="supportmanagment" href="https://app.boomfi.xyz/customer">click here</a>. If the issue continues, please contact our support service.</p>
    </div>}



</div>

      </div>
    </div>
    </main>

  );
}

/*

<button onClick={()=>{setCancel(true);
    console.log(cancel);
}} type="button" class="mt-12 bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded" >Cancel Suscription</button>

{ true && cancel ? <button  onClick={ async ()=>{
  try{
   const success =  await handleCancel(susId);
   console.log("return api---",success);
    if(success){

      setIssuccess(success);

    }else{

       setIserror(true);


    }
    setCancel(false);

  }catch(e){
       setIserror(true);
  }


} } type="button" class="mt-12 ml-8 bg-red-700 hover:bg-blue-700 text-white  py-2 px-4 rounded " >Click To Confirm</button>

: ''

}

{issuccess && <div class="mt-10">
 <div  class="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Your Suscription have been cancel successfully </div>
</div>}

{iserror && <div class="mt-10">
  <div  class="block w-full rounded-md bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Oup, sorry something when wrong</div>
  <p class="mt-8">If the error persists, proceed to the <a class="supportmanagment"  href="https://pay.boomfi.xyz/2jqO1xzlJtk3QeKfvmBCM9rKwjy">checkout form</a>. Enter your credentials, including name and email, confirm, and click on "Manage Subscription."
  From there, you can cancel your subscription or for a more direct approach <a class="supportmanagment" href="https://app.boomfi.xyz/customer">click here</a>. If the issue continues, please contact our support service.</p>
</div>}



*/
