import { useState } from "react";
import { useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";

import { sendContact } from "../db/database"



export default function Contact({data}) {

  const [inputMessageValue, setInputMessageValue] = useState("");
  const [inputEmailValue, setInputEmailValue] = useState("");
  const [errormsg,setErrormsg] =  useState();
  const [successfulmsg,setSuccessfulmsg] =  useState("Send");
  const [issuccess,setIssuccess] =  useState(false);





  const handleInputMessageChange = (event) => {
    setInputMessageValue(event.target.value);

  };

  const handleInputEmailChange = (event) => {
    setInputEmailValue(event.target.value);

  };

       const  handlePress = ()=>{

         event.preventDefault();
           setErrormsg("")
         let err = false;
         if (inputMessageValue.trim() === "") {
           // Input is empty
           setErrormsg("Message: input is empty")

            err=true;

         }

         if (inputEmailValue.trim() === "") {
           // Input is empty
           setErrormsg("Email: input is empty")


             err=true;
         }

          if(!err){
          console.log("contact----");
          sendContact({
           email:inputEmailValue.trim(),
           message:inputMessageValue.trim()
         });
         setSuccessfulmsg("your message has been sent!");
         setIssuccess(true);

       }

       };

  return (
    <div class="ml-60 pt-16 max-h-screen overflow-auto">
    <div class="px-6 py-8">
      <div class="max-w-4xl mx-auto">



      <div class="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="">
       <div class="" ></div>
      </div>
      <div class="mx-auto max-w-2xl text-center">
       <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contact Support</h2>
       <p class="mt-2 text-lg leading-8 text-gray-600">{errormsg}</p>
      </div>
      <form  class="mx-auto mt-16 max-w-xl sm:mt-20">
       <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
         <div>
           <label htmlFor="first-name" class="block text-sm font-semibold leading-6 text-gray-900">First name</label>
           <div class="mt-2.5">
             <input type="text"
              name="first-name" id="first-name" autoComplete="given-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
           </div>
         </div>
         <div>
           <label htmlFor="last-name" class="block text-sm font-semibold leading-6 text-gray-900">Last name</label>
           <div class="mt-2.5">
             <input type="text" name="last-name" id="last-name" autoComplete="family-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
           </div>
         </div>

         <div class="sm:col-span-2">
           <label htmlFor="email" class="block text-sm font-semibold leading-6 text-gray-900">Email</label>
           <div class="mt-2.5">
             <input type="email"
             value={inputEmailValue}
               onChange={handleInputEmailChange}
             name="email" id="email" autoComplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
           </div>
         </div>

         <div class="sm:col-span-2">
           <label htmlFor="message" class="block text-sm font-semibold leading-6 text-gray-900">Message</label>
           <div class="mt-2.5">
             <textarea value={inputMessageValue}
               onChange={handleInputMessageChange} name="message" id="message" rows="4" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
           </div>
         </div>
       </div>
       <div class="mt-10">
         <button onClick={ handlePress } class="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{successfulmsg}</button>
       </div>
      {issuccess && <div class="mt-10">
         <div  class="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">we will be in touch soon, Thank</div>
       </div>}
      </form>
      </div>



      </div>
    </div>
    </div>

  );


}
