import React from 'react';

import Header from "./Header";
import Aside from "./Aside";
import Main from "./Main";
import { useState } from "react";
import { useEffect } from "react";
import {useLocation} from 'react-router-dom';
import { readDB } from '../db/database';
import {auth} from "../db/firebase";
import { getAuth, signOut ,onAuthStateChanged } from "firebase/auth";
import { Routes,Route,Link,useNavigate } from "react-router-dom";
import  Profile  from "./Profile";
import Contact from "./Contact";
import Suscription from "./Suscription";
import {updateUserData} from "../db/database"
//import Stripe from 'stripe';


export default function Dashboard() {


        /**    const lemonsqueezy = async () => {


             const apikey  = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NGQ1OWNlZi1kYmI4LTRlYTUtYjE3OC1kMjU0MGZjZDY5MTkiLCJqdGkiOiIxZmVlNzE1OWUwNjY5NGNmMDE5Mjk1ODhmNDM4YzVkNDE0ZDI0M2UzZTU3NzM5MmVlMGNhOTQyYzQ2NGVhYTAwN2Q0N2ViODIxMTY1ZDM2MSIsImlhdCI6MTcyMjgxODA1My41MTczNzksIm5iZiI6MTcyMjgxODA1My41MTczODEsImV4cCI6MjAzODM1MDg1My40NjA0MzMsInN1YiI6IjI5Njc5NzMiLCJzY29wZXMiOltdfQ.M7uobU5HV90Vm_MhwJUGuWmhYBbmSKYUanViWLdtEXoKvjTCRU1L0JFrVbMrP3s-A1X7v6u9cozWR9SJPI5zOlpN6MKcqkpgazNIw5i0o8ltO0zbderIYtLpKlByIBpJEYCijVP_cvAjop-pTVIdc8INFrCSKly9paqCjclNddQ6JSMT1Hpio3jsGGcX_otd8piDjUt9cI_X4mtv-k1HaYhaj0w6qsaMJtikV5bH94gsjRBvVwDFkswJJGrqdAYUgqVaSuuSdLOIKQKCsJPP_VLbv5LzPRSnb4q_pYe6yULm9SzSK9KJmE1tgUy2xMqGvsZgpORqW_2c7lbVzzqx7PEST7K08nP2dpXHn0AgJ5_hJDV7uA9JHK9pxSohTh6mjXZbMRTM6gkNthB4n4bWB43_OS7QiMqgU37GvVWPayMgvAXrOi4ypM1AHFjlZZwUmOMbpwGueAc22p4Is19dDutCanHj9ZMk3bNYj9IcneA-XJKB4kuXGjVUqgmmOiLa";
              console.log("api key",apikey);
             lemonSqueezySetup({apikey,
              onError:(error) => console.error("Error!", error)
            });

             const { data, error } = await getAuthenticatedUser();

                     if (error) {
                         console.log(error.message);
                     } else {
                        console.log(data);
                     }


           }

              async function fetchData(d) {
              // You can await here
            //  const response = await MyAPI.getData(someId);
            const stripe = new Stripe('sk_test_51P7LzRDeQQ3MUrpaFaCmrSV5dsuSsw8xqrRrxm06UOvkjid0GT2Izu9ZtfeJhoUgSXjsOBA0J1PoOzmz3gkTGibT000YRvD8EP');

                const customers = await stripe.customers.search({
                  query: `email:\'${d}\'`
                });
            // console.log("suscription",customers.data[0].id);
                  const cusid = customers.data[0]?.id;
                   console.log('rewriting suscription----',cusid);
               if( cusid !== undefined ){
                 console.log('rewriting suscription++++');
                  updateUserData(d,{suscription:true});
               }else{
                 return null;
               }

                return customers.data[0].id;

            }
            **/









  const location = useLocation();
  const navigate = useNavigate();
 let  d = null;
 let user = null;
 const [data, setdata] = useState();

 const [profile, setProfile] = useState(true);
 const [stats, setStats] = useState(false);
 const [suscription, setSuscription] = useState(false);
 const [support, setSupport] = useState(false);
 const [edit, editPage] = useState(false);

 const [toggle, setToggle] = useState({
                           profile:false,
                           stats:true,
                           suscription:false,
                           support:false,
                           editpage:false,

                                       });



        const toogleFn = ( p,s,sus,sup,etd)=>{
                   console.log("efdect---",toggle);

                         setToggle({
                          profile:p,
                          stats:s,
                          suscription:sus,
                          support:sup,
                          editpage:etd


                          });


         };

        const signOutUser = ()=>{
              signOut(auth).then(() => {
            // Sign-out successful.
              }).catch((error) => {
                // An error happened.
              });
        }











        if(location.state!=null){
            d = location.state.email;
        }else if(auth.currentUser!=null){
             user = auth.currentUser;
             d = user.email;
             console.log("user auth--",d);

        }else{
                  console.log("Not user Co--");
                  navigate('/');

        }


        console.log("user Dashboard--",user);
       if(!user && !d) navigate('/');

  // d = location.state.email;
          useEffect(() => {

            // lemonsqueezy();

            onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/auth.user



              console.log("user fire Dashboard--",user);
              d = user.email;

                    readDB(d).then(
                      (data)=>{
                        setdata(data);
                      //  if(true){
                      //     fetchData(d);
                    //    }
                      }
                                  );
              // ...
            } else {
              // User is signed out
              console.log("user signed out--");
              navigate('/');


            }
            });


             if(d)
                 readDB(d).then(
                   (data)=>{
                     setdata(data);
                  //   if(true){
                      //  fetchData(d);
                    // }

                   }
           );



         }, []);


        if(!data) return (<div class='flex space-x-2 justify-center items-center bg-white h-screen dark:invert'>
       	<span class='sr-only'>Loading...</span>
        	<div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
      	<div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
      	<div class='h-8 w-8 bg-black rounded-full animate-bounce'></div>
      </div>);

          return (
            <body class="relative overflow-hidden max-h-screen">

              <Header toggle={toggle} email={d} data={data} section={ (p,s,sus,sup,etd)=>{ toogleFn(p,s,sus,sup,etd) } } />

              <Aside email={d} data={data} toggle={toggle} section={ (p,s,sus,sup,etd)=>{ toogleFn(p,s,sus,sup,etd) } } />

              { toggle.profile && <Profile data={data} /> }
              { toggle.stats && <Main email={d} data={data} /> }
              {   toggle.support && <Contact/ > }

              {   toggle.suscription && <Suscription email={d} data={data} /> }




            </body>
          );
        }
