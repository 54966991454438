import React from 'react';

import { useState } from "react";
import { useEffect } from "react";

export default function ErrorPage() {

  return (
    <div id="errpg">
    	<div class="errf">
        		<h1>User Not Found</h1>

    	</div>
     </div>
  );
}
