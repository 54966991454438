import React from 'react';

import ErrorPage from "./ErrorPage";
import CardForm from "./CardForm";
import CardPage from "./CardPage";
import Dashboard from "./dashboard/Dashboard";
import Landing from "./landingpage/Landing";
import EditCardPage from "./EditCardPage";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



let router;
if (typeof window !== 'undefined') {

 router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/signup",
    element: <CardForm/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
      path: "/edit",
      element: <EditCardPage   />

    },

  {
    path: "/:profile",
    element: <CardPage pdata={window.__APP_INITIAL_STATE__} />,
    errorElement: <ErrorPage />,

  }

]);
}



export default function App() {
  return (

    router ?   <RouterProvider router={router}  />   : null


  );
}
