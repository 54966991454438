import { BarChart } from '@tremor/react';



const dataFormatter = (number) =>
  Intl.NumberFormat('us').format(number).toString();

export const StatChart = ({data}) => {

  console.log("REQ-",data.day.results[0][0]);
  const day = data.day.results[0][0];
  const week = data.week.results[0][0];
  const month = data.month.results[0][0];
  const year = data.year.results[0][0];

  console.log("REQ-",day,week,month);


  const cdata = [



    {
      name:'year', 'visitor': year,
    },
    {
      name:'month', 'visitor': month,
    },

    {
      name:'week', 'visitor': week,
    },
    {
      name:'today', 'visitor': day,
    },
  ];

  return ( <BarChart
    data= {cdata}
    index= "name"
    categories= {['visitor']}
    colors= {['black']}
    valueFormatter= {dataFormatter}
    yAxisWidth= {100}
  />
);
}
