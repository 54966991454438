import React from 'react';
import ReactDOM from 'react-dom';
import { StrictMode } from "react";
import { createRoot,hydrateRoot } from "react-dom/client";

import App from "./App";
import ErrorPage from "./ErrorPage";
import CardForm from "./CardForm";
import CardPage from "./CardPage";
import Dashboard from "./dashboard/Dashboard";
import Landing from "./landingpage/Landing";
import posthog from 'posthog-js';
import EditCardPage from './EditCardPage';
 


posthog.init('phc_5F6zp86yA78dxSP7XDGVvgtMVrZcrgYXxIvbxhjJtmZ');

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/signup",
    element: <CardForm/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },

  {
      path: "/edit",
      element: <EditCardPage   />

    },


  {
    path: "/:profile",
    element: <CardPage pdata={window.__APP_INITIAL_STATE__} />,
    errorElement: <ErrorPage />,

  }


]);
// You can do this:

/*



*/





const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

 hydrateRoot( rootElement , <App  />);

/** root.render(

  <RouterProvider router={router} />



); */
