import { useState } from "react";
import { useEffect } from "react";

import {auth} from "../db/firebase";
import { getAuth, signOut ,onAuthStateChanged } from "firebase/auth";
import {
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom';
import Header from "./Header";
import Main from "./Main";
import  Profile  from "./Profile";


export default function Aside({ section,toggle,email,data }) {

  const navigate = useNavigate();

  const handlePress = (p,s,sup,sus) => {
    console.log("effect work");
    //section(p,s,sup,sus);

  };

  const signOutUser = ()=>{

        signOut(auth).then(() => {
      // Sign-out successful.
      console.log("signed out");
      navigate('/');

        }).catch((error) => {
          // An error happened.
            console.log("signed out fn",error);
        });
  };

  return (
    <aside class="fixed inset-y-0 left-0 bg-white shadow-md max-h-screen w-60">
      <div class="flex flex-col justify-between h-full">
        <div class="flex-grow">
        <div class="flex lg:flex-1 ml-4 mt-2">
          <a href="#" class="-m-1.5 p-1.5 logolk">

            <svg class="h-11 w-auto" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="559.000000pt" height="447.000000pt" viewBox="0 0 559.000000 447.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M3644 3850 c-51 -11 -107 -33 -144 -60 -25 -18 -149 -148 -289 -305
            -20 -22 -92 -102 -160 -178 -103 -114 -122 -141 -115 -157 13 -27 201 -200
            218 -200 8 0 88 82 179 183 191 212 305 323 353 343 78 33 141 13 234 -72 101
            -94 480 -514 486 -540 3 -13 3 -35 0 -48 -3 -13 -168 -203 -367 -422 -199
            -220 -486 -536 -638 -704 -307 -338 -359 -395 -446 -488 -33 -35 -64 -70 -68
            -78 -20 -36 -54 -9 -329 261 -154 151 -286 275 -293 275 -14 0 -86 -81 -129
            -144 -32 -48 -36 -125 -8 -171 17 -27 713 -716 731 -723 8 -3 94 86 237 243
            50 55 162 179 250 275 685 755 1242 1370 1359 1500 76 85 152 169 168 187 l30
            32 -269 277 c-455 468 -628 640 -675 667 -81 48 -212 67 -315 47z"/>
            <path d="M1740 3796 c-178 -52 -175 -49 -885 -858 -71 -82 -132 -148 -135
            -148 -4 0 -12 -11 -18 -24 -10 -23 6 -41 331 -358 188 -183 384 -373 435 -423
            l94 -90 50 55 c95 105 119 174 88 255 -6 17 -128 147 -276 295 -145 146 -264
            269 -264 274 0 19 340 418 464 544 102 104 145 132 207 132 91 0 93 -1 854
            -767 396 -398 725 -723 730 -723 13 1 136 154 152 189 18 38 16 77 -6 119 -28
            56 -1403 1418 -1473 1460 -113 67 -256 95 -348 68z"/>
            <path d="M2169 2323 c-167 -186 -342 -383 -389 -438 -46 -55 -124 -145 -172
            -200 -48 -55 -88 -104 -88 -109 0 -5 51 -60 113 -123 l112 -114 90 103 c50 56
            128 145 175 198 299 338 588 668 606 692 57 76 39 154 -59 255 -39 40 -74 73
            -78 73 -4 0 -143 -152 -310 -337z"/>
            </g>
            </svg>
            <span class="">Lk In Bio</span>
          </a>
        </div>
          <div class="p-4">
            <ul class="space-y-1">
              <li>
                <button
                  onClick={ ()=> {section( false,true, false, false,false );} }
                  className={` asidebtn flex items-center ${ toggle.stats && 'bg-yellow-200'} rounded-xl font-bold text-sm text-black-900 py-3 px-4`}
                >

                  <svg xmlns="http://www.w3.org/2000/svg" width="1rem" class="text-lg mr-4" height="1rem" viewBox="0 0 576 512">
                  <path d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z"/></svg>



                  Stats
                </button>
              </li>
              <li>
                <button
                  onClick={ ()=>{section( true,false, false, false,false )} }
                  className={`asidebtn flex bg-white hover:bg-yellow-50  ${ toggle.profile && 'bg-yellow-200'} rounded-xl font-bold text-sm text-gray-900 py-3 px-4`}
                >
              <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 576 512"
               width="1em"
               height="1em"
               fill="currentColor"
               class="text-lg mr-4"
               >
              <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                  Profile
                </button>
              </li>
              <li>
                <a
                  onClick={ ()=>{
                    section( false, false, false,false,true )
                    if(email)
                    navigate("/edit",{ state: {data:data,email:email}  });
                  } }
                  className={`asidebtn flex bg-white hover:bg-yellow-50 ${ toggle.editpage && 'bg-yellow-200'} rounded-xl font-bold text-sm text-gray-900 py-3 px-4`}
                >
            <svg   width="1em"
              height="1em"
              fill="currentColor"
              class="text-lg mr-4"
                viewBox="0 0 512 512"> <path d="M0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zm64 32l0 64c0 17.7 14.3 32 32 32l320 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32L96 128c-17.7 0-32 14.3-32 32zM80 320c-13.3 0-24 10.7-24 24s10.7 24 24 24l56 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-56 0zm136 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l48 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0z"/></svg>
                  Edit Page
                </a>
              </li>
              <li>
                <button
                  onClick={ ()=>{section( false, false, true, false,false ) }}
                  className={`asidebtn flex bg-white hover:bg-yellow-50 ${ toggle.suscription && 'bg-yellow-200'} rounded-xl font-bold text-sm text-gray-900 py-3 px-4`}
                >
                <svg xmlns="http://www.w3.org/2000/svg"

                viewBox="0 0 576 512"
                width="1em"
                height="1em"
                fill="currentColor"
                class="text-lg mr-4"
                 >
                <path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"/></svg>
                  Suscription
                </button>
              </li>
              <li>
                <button
                  onClick={ ()=>{section( false, false, false, true,false )} }
                  className={`asidebtn flex bg-white hover:bg-yellow-50 ${ toggle.support && 'bg-yellow-200'} rounded-xl font-bold text-sm text-gray-900 py-3 px-4`}
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                width="1em"
                height="1em"
                fill="currentColor"
                class="text-lg mr-4"
                >
                <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
                  Support
                </button>
              </li>


            </ul>
          </div>
        </div>
        <div class="p-4">
          <button
            onClick={signOutUser}
            type="button"
            className="inline-flex items-center justify-center h-9 px-4 rounded-xl bg-gray-900 text-gray-300 hover:text-white text-sm font-semibold transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              class=""
              viewBox="0 0 16 16"
            >
              <path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
          </button>
          <span    className="font-bold text-sm ml-2">Logout</span>
        </div>
      </div>
    </aside>

  );
}
