import React from 'react';

import { auth } from '../db/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { checkUserSignUp } from "../db/database";
import { getServerSideProps } from "../db/database";
import Lottie from 'react-lottie';
import * as animationData from './anim/Animation.json';
import * as animationBannerData from './anim/banner.json';
import Terms from "./Terms";
import fimg from "../assets/feature.png";
import { Helmet } from 'react-helmet';






export default function Landing() {









                      const defaultOptions = {
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                    }
                    };

                    const defaultBOptions = {
                  loop: true,
                  autoplay: true,
                  animationData: animationBannerData,
                  rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                   }
                  };

                    const navigate = useNavigate();

                    const logIn = async () => {

                    const provider = new GoogleAuthProvider();
                    const auth = getAuth();

                     signInWithPopup(auth, provider)
                      .then((result) => {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const credential = GoogleAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;
                        // The signed-in user info.
                        const user = result.user.email;
                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                        console.log("log in-----",user);
                        checkUserSignUp(user).then((url)=>{
                            console.log("log in url-----",url);
                               if(url){
                                  navigate('/dashboard',{ state:{ email:user}  } );
                               }else{
                                  navigate('/signup',{ state:{ email:user}  } );
                               }
                        }

                        );


                      }).catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        //    const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential = GoogleAuthProvider.credentialFromError(error);
                        // ...
                        console.log("-----",errorMessage);
                      });
                  };


                    const signIn = async () => {

                    const provider = new GoogleAuthProvider();
                    const auth = getAuth();

                    await signInWithPopup(auth, provider)
                      .then((result) => {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const credential = GoogleAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;
                        // The signed-in user info.
                        const user = result.user.email;
                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                        console.log("-----",user);

                        navigate('/signup',{ state:{ email:user}  } );
                      }).catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        //    const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential = GoogleAuthProvider.credentialFromError(error);
                        // ...
                        console.log("-----",errorMessage);
                      });
                  };

  return (
     <>

     <Helmet>
     <title>lkn.bio: Create Your Powerful Link in bio - Unlimited Visits & Links</title>
      <meta name="description" content="Create a stunning link in bio with lkn.bio. Showcase all your online profiles and websites in one place. Enjoy unlimited visits and links, plus analytics & QR code generation. Sign up now!" />
      <meta name="keywords" content="bio link, crypto payments, link in bio, linktree alternative, multi-link, social media links, landing page, website builder, analytics, qr code, unlimited visits, unlimited links" />
   </Helmet>



    <div class="header  bg-white">

    <header class="">
      <nav class="hnav flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="#" class="-m-1.5 p-1.5 logolk">

            <svg class="h-11 w-auto" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="559.000000pt" height="447.000000pt" viewBox="0 0 559.000000 447.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M3644 3850 c-51 -11 -107 -33 -144 -60 -25 -18 -149 -148 -289 -305
            -20 -22 -92 -102 -160 -178 -103 -114 -122 -141 -115 -157 13 -27 201 -200
            218 -200 8 0 88 82 179 183 191 212 305 323 353 343 78 33 141 13 234 -72 101
            -94 480 -514 486 -540 3 -13 3 -35 0 -48 -3 -13 -168 -203 -367 -422 -199
            -220 -486 -536 -638 -704 -307 -338 -359 -395 -446 -488 -33 -35 -64 -70 -68
            -78 -20 -36 -54 -9 -329 261 -154 151 -286 275 -293 275 -14 0 -86 -81 -129
            -144 -32 -48 -36 -125 -8 -171 17 -27 713 -716 731 -723 8 -3 94 86 237 243
            50 55 162 179 250 275 685 755 1242 1370 1359 1500 76 85 152 169 168 187 l30
            32 -269 277 c-455 468 -628 640 -675 667 -81 48 -212 67 -315 47z"/>
            <path d="M1740 3796 c-178 -52 -175 -49 -885 -858 -71 -82 -132 -148 -135
            -148 -4 0 -12 -11 -18 -24 -10 -23 6 -41 331 -358 188 -183 384 -373 435 -423
            l94 -90 50 55 c95 105 119 174 88 255 -6 17 -128 147 -276 295 -145 146 -264
            269 -264 274 0 19 340 418 464 544 102 104 145 132 207 132 91 0 93 -1 854
            -767 396 -398 725 -723 730 -723 13 1 136 154 152 189 18 38 16 77 -6 119 -28
            56 -1403 1418 -1473 1460 -113 67 -256 95 -348 68z"/>
            <path d="M2169 2323 c-167 -186 -342 -383 -389 -438 -46 -55 -124 -145 -172
            -200 -48 -55 -88 -104 -88 -109 0 -5 51 -60 113 -123 l112 -114 90 103 c50 56
            128 145 175 198 299 338 588 668 606 692 57 76 39 154 -59 255 -39 40 -74 73
            -78 73 -4 0 -143 -152 -310 -337z"/>
            </g>
            </svg>
            <span class="">Lkn Bio</span>
          </a>
        </div>
        <div class="flex snave lg:hidden sm:hidden md:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
            <span class="sr-only">Open main menu</span>

          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12  items-end ">


        </div>
        <div  class=" lg:flex lg:flex-1 lg:justify-end">
        <button id="sgbox" onClick={signIn} class="bripple mr-5 inline-flex items-center px-6 py-2 text-sm text-white transition-all duration-500 ease-in-out transform bg-black rounded-md hover:text-white md:mb-2 lg:mb-0 hover:border-white hover:bg-slate-500 focus:ring-2 ring-offset-current ring-offset-2">
         Sign Up</button>

          <button onClick={logIn} class="bripple inline-flex items-center px-6 py-2 text-sm text-white transition-all duration-500 ease-in-out transform bg-black rounded-md hover:text-white md:mb-2 lg:mb-0 hover:border-white hover:bg-slate-500 focus:ring-2 ring-offset-current ring-offset-2">
           Log in<span aria-hidden="true"> &rarr;</span></button>

        </div>

     </nav>


    </header>

    <div class="bnca  px-6 pt-1 mt-8 ">



      <div class="mx-auto max-w-2xl py-12 sm:py-12 lg:py-12">
        <div class="hidden sm:mb-8 sm:flex sm:justify-center">
          <div class="mb-4 text-xs font-semibold tracking-widest uppercase text-slate-500">
    Cryptocurrency is our only payment method.

 <a href="#feature" class="font-semibold text-indigo-600"><span class=" inset-0" aria-hidden="true"></span> Read more <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
        <div class="text-center">






          <h1 class="mb-6 font-serif text-xl font-bold tracking-tighter text-black md:text-2xl lg:text-4xl">We help you create a central hub for all your social media accounts.</h1>
         <div id="lottiesanim">
          <Lottie options={defaultBOptions} width={500} height={350}   />
          </div>

          <p class="opactext mt-6 text-lg leading-8 text-black-800">Social media platforms like Instagram typically only allow one clickable link in your bio. A link-in-bio tool lets you create a custom landing page that holds multiple links.</p>
          <div class="mt-10 flex-col flex items-center justify-center gap-x-6">




                                  <div class="clpg">
                                   <div class="clpgb">https://lkn.bio/</div>
                                    <input

                                      placeholder="yourname"
                                      type="text"

                                    />
                                    <button onClick={signIn} class="clpgbt ripple">Claim Your Page</button>
                                  </div>



                                  <button onClick={signIn} class=" mt-10   inline-flex items-center px-8 py-3 text-lg text-white transition-all duration-500 ease-in-out transform bg-black border-2 border-black rounded-lg md:mb-2 lg:mb-0  focus:ring-2 ring-offset-current ring-offset-2">
                                         Get started
                                       </button>




          </div>
        </div>


      </div>

    </div>


  </div>





  <div id="feature" class="bg-white ">
    <div class="fmrg mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">Enhance You Bio</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Don't limit yourself to a single, plain link.</p>
        <p class="mt-6 text-lg leading-8 text-gray-600">Transform your Instagram profile into a dynamic marketing hub with a stunning, multi-link landing page.</p>
      </div>

      <img src={fimg} id="featureimg"  />


      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#fff" width='16' height="16">
              <path d="M0 160v96C0 379.7 100.3 480 224 480s224-100.3 224-224V160H320v96c0 53-43 96-96 96s-96-43-96-96V160H0zm0-32H128V64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v64zm320 0H448V64c0-17.7-14.3-32-32-32H352c-17.7 0-32 14.3-32 32v64z"/></svg>
              </div>
            Drive Traffic
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">Showcase all your important links in one, professional space. Send followers to your website, portfolio, social media channels, and more.</dd>
          </div>
          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#fff" width='16' height="16">
                <path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z"/></svg>
              </div>
              Boost Engagement
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">Spark curiosity and encourage clicks with eye-catching visuals and clear calls to action.</dd>
          </div>
          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff" width='16' height="16">
              <path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"/></svg>
              </div>
              Stand Out from the Crowd
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600"> Ditch the generic link and create a unique, branded experience that reflects your personality or business.</dd>
          </div>
          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                <svg class="h-6 w-6 text-white" fill="none" viewbox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                </svg>
              </div>
            Analytics & Insights
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">Track clicks and see what resonates with your audience.</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>

   <div class="mrff relative isolate overflow-hidden bg-white px-6  lg:overflow-visible lg:px-0">
    <div class="absolute inset-0 -z-10 overflow-hidden">

    </div>

    <div id="product" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      <div class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="lg:max-w-lg">
            <p class="text-base font-semibold leading-7 text-indigo-600">Power Up</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better Link In Bio</h1>
            <p class="mt-6 text-xl leading-8 text-gray-700">
            Optimize for maximum conversions while cultivating long-term customer relationships.
            </p>
          </div>
        </div>
      </div>
      <div id="lottieanimtwo" class=" undraw  p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">

      <Lottie options={defaultOptions}    />
                                                                                                                       </div>
      <div class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
            <p>In today's digital landscape, with countless platforms vying for attention, it's crucial to make connecting with you as easy and streamlined as possible. That's where a powerful link-in bio tool comes in. Gone are the days of forcing your audience to hunt down your various social media channels or websites buried within a generic bio link. A single, well-crafted link in bio acts as your online command center, centralizing all the vital connections your audience craves. Here's why it's the epitome of efficiency:
</p>
            <ul role="list" class="mt-8 space-y-8 text-gray-600">
              <li class="flex gap-x-3">

                <span><strong class="font-semibold text-gray-900">Streamlined Navigation: </strong>
   Ditch the scavenger hunt! we offer a single, central location for all your social media channels and online destinations. Followers can effortlessly explore your complete digital presence with just a tap, eliminating frustration and keeping them engaged within your online ecosystem.              </span></li>
              <li class="flex gap-x-3">


                <span><strong class="font-semibold text-gray-900">Tailored Engagement: </strong>
                Not everyone is interested in everything you do. A link in bio landing page allows you to curate specific links based on the platform.  An Instagram follower might be more interested in your artistic pursuits on Behance, while a professional connection on LinkedIn might appreciate a direct link to your portfolio website.
                 </span>
              </li>
              <li class="flex gap-x-3">



                <span><strong class="font-semibold text-gray-900">Effortless Updates: </strong>
Gone are the days of updating multiple bios. With a link in bio tool, you can effortlessly add, remove, or edit links as your needs change.                 </span>
              </li>

             <li class="flex gap-x-3">
              <span><strong class="font-semibold text-gray-900">SEO Optimized Page: </strong>
              Every page created within the platform is automatically optimized for search engine discovery. By applying best SEO practices, the feature improves page visibility, organic traffic, and overall search engine rankings
                </span>
            </li>



            </ul>
            <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">No coding headaches, just pure efficiency</h2>
            <p class="mt-6">
            The beauty of a link in bio tool extends beyond its functionality. Unlike the complexities of website development, setting up a link in bio landing page is a breeze, all within a matter of minutes.

            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <style>@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')</style>

  <div id="testimmony" class="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center py-5">
      <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-16 md:py-24 text-gray-800">
          <div class="w-full max-w-6xl mx-auto">
              <div class="text-center max-w-xl mx-auto">
                  <h1 class="text-6xl md:text-7xl font-bold mb-5 text-gray-600">What makes us special?</h1>
                  <h3 class="text-xl mb-5 font-light">Here's a breakdown of what it offers.</h3>
                  <div class="text-center mb-10">
                      <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                      <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                      <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                      <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                      <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                  </div>
              </div>
              <div class="-mx-3 md:flex items-start">
                  <div class="px-3 md:w-1/3">
                      <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                          <div class="w-full flex mb-4 items-center">

                              <div class="flex-grow ">
                                  <h6 class="font-bold text-sm uppercase text-gray-600">1 bio page</h6>
                              </div>

                          </div>
                          <div class="w-full">
                              <p class="text-sm zain-regular leading-tight">
Design and personalize a dedicated page to showcase all your important online profiles and websites. </p>
                          </div>
               </div>



               <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                   <div class="w-full flex mb-4 items-center">

                       <div class="flex-grow ">
                           <h6 class="font-bold text-sm uppercase text-gray-600">Customizable Page</h6>
                       </div>

                   </div>
                   <div class="w-full">
                       <p class="text-sm zain-regular leading-tight">
Personalize your page with our bento-style builder. Easily drag, drop, and resize elements to create a unique look. </p>
                   </div>
        </div>



    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                          <div class="w-full flex mb-4 items-center">

                              <div class="flex-grow ">
                                  <h6 class="font-bold text-sm uppercase text-gray-600">Analytics & Insights</h6>
                              </div>

                          </div>
                          <div class="w-full">
                              <p class="  zain-regular leading-tight"><span class="text-lg leading-none italic font-bold text-gray-400 mr-1"></span>
                              You'll be able to see how people are interacting with your bio page, such as how many times it's been viewed and which links are being clicked the most. This data can help you understand your audience and optimize your bio page for better results.
                               <span class="text-lg leading-none italic font-bold text-gray-400 ml-1"></span></p>
                          </div>
                      </div>
                  </div>
                  <div class="px-3 md:w-1/3">
                      <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                          <div class="w-full flex mb-4 items-center">

                              <div class="flex-grow ">
                                  <h6 class="font-bold text-sm uppercase text-gray-600">Unlimited visits/mo</h6>
                              </div>
                          </div>
                          <div class="w-full">
                              <p class=" zain-regular leading-tight">
                              There's no cap on the number of monthly visits to your bio page.

   </p>                       </div>
                      </div>
                      <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                          <div class="w-full flex mb-4 items-center">

                              <div class="flex-grow ">
                                  <h6 class="font-bold text-sm uppercase text-gray-600">Unlimited Links</h6>
                              </div>
                          </div>
                          <div class="w-full">
                              <p class="  zain-regular leading-tight">
You can add as many links to your bio page as you want, which gives you plenty of flexibility to showcase all your online destinations.
                             </p>
                          </div>
                      </div>
                  </div>
                  <div class="px-3 md:w-1/3">
                      <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                          <div class="w-full flex mb-4 items-center">

                              <div class="flex-grow ">
                                  <h6 class="font-bold text-sm uppercase text-gray-600">Easy Donations</h6>
                              </div>
                          </div>
                          <div class="w-full">
                              <p class="  zain-regular leading-tight">
                          It gives your followers a convenient way to support you financially with just a few clicks.
                          </p>
                          </div>
                      </div>
                      <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                          <div class="w-full flex mb-4 items-center">

                              <div class="flex-grow ">
                                  <h6 class="font-bold   uppercase text-gray-600">QR Code Option</h6>
                              </div>
                          </div>
                          <div class="w-full">
                              <p class="  zain-regular eading-tight">
You can generate a QR code that directs people to their bio page. This makes it easy for people to connect with them offline (physical marketing materials like business cards, flyers, posters, etc ) by simply scanning the code with their smartphones.                    </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>



    <div id="price" class="bg-white py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl sm:text-center">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Use Your Crypto, Help The Market</h2>
          <p class="mt-6 text-lg leading-8 text-gray-600">Enjoy a hassle-free subscription plan for your link in bio landing page. Pay with crypto and avoid any hidden fees. For just $3 per month, unlock all the features and maximize your online presence.</p>
        </div>
        <div  class="pbh mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div class="p-8 sm:p-10 lg:flex-auto">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900">Subscription Membership</h3>
            <p class="mt-6 text-base leading-7 text-gray-600">Our subscription offers a premium package with current and future features, all at a consistent price.</p>
            <div class="mt-10 flex items-center gap-x-4">
              <h4 class="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div class="h-px flex-auto bg-gray-100"></div>
            </div>
            <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
                1 bio page

              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
                Analytics & Insights

              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
            Unlimited visits/mo
              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
            Unlimited visits/mo
              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
                Unlimited Links
              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
                Donations Links
              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
                QR Code Generation
              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
                Google Analytics Integration
              </li>
              <li class="flex gap-x-3">
                <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
                24/7 Support Service (Real Human)
              </li>
            </ul>
          </div>


          <div class="rounded-2xl h-full bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 sbh">
            <div class="mx-auto max-w-xs px-8">
              <p class="text-base font-semibold text-gray-600">For a small price of</p>
              <p class="mt-6 flex items-baseline justify-center gap-x-2">
                <span class="text-5xl font-bold tracking-tight text-gray-900">$3</span>
                <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
              </p>
              <button onClick={signIn} class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</button>
              <p class="mt-6 text-xs leading-5 text-gray-600">Invoices and receipts available for easy company reimbursement </p>
            </div>
          </div>





        </div>
      </div>
    </div>





  <section class="bg-white">
      <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
          <nav class="flex flex-wrap justify-center -mx-5 -my-2">
              <div class="px-5 py-2">
                  <a href="#product" class="text-base leading-6 text-gray-500 hover:text-gray-900">
                      Product
                  </a>
              </div>

              <div class="px-5 py-2">
                  <a href="#feature" class="text-base leading-6 text-gray-500 hover:text-gray-900">
                      Features
                  </a>
              </div>


              <div class="px-5 text-gray-500 py-2">
                    <Terms/>



              </div>
          </nav>

          <p class="mt-8 text-base leading-6 text-center text-gray-400">
              © 2024 NeptuneStudio, Inc. All rights reserved.
          </p>
      </div>
  </section>
  </>
  );


}

/**





<div id="price" class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl sm:text-center">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Free Plan, Powerful Results.</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
      We offer a Free plan that's clear and straightforward. </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
      <div class="p-8 sm:p-10 lg:flex-auto">
        <h3 class="text-2xl font-bold tracking-tight text-gray-900">Free membership</h3>
        <p class="mt-6 text-base leading-7 text-gray-600">Our Free service offers a convenient way to unlock the full potential of your link in bio landing page.</p>
        <div class="mt-10 flex items-center gap-x-4">
          <h4 class="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
          <div class="h-px flex-auto bg-gray-100"></div>
        </div>
        <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            1 bio page

          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Analytics & Insights

          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
        Unlimited visits/mo
          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Unlimited Links
          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Donations Links
          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            QR Code Generation
          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Google Analytics Integration
          </li>
          <li class="flex gap-x-3">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Ads (to help us pay bills)
          </li>
        </ul>
      </div>
      <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
        <div class="rounded-2xl h-full bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
          <div class="mx-auto max-w-xs px-8">
            <p class="text-base font-semibold text-gray-600">For a small price of</p>
            <p class="mt-6 flex items-baseline justify-center gap-x-2">
              <span class="text-5xl font-bold tracking-tight text-gray-900">$0</span>
              <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
            </p>
            <button onClick={signIn} class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign up for free</button>
            <p class="mt-6 text-xs leading-5 text-gray-600">Future plan will be available </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>








<div class="flex justify-center mt-8 space-x-6">


</div>


<div class="flex justify-center mt-8 space-x-6">
    <a href="#" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">Facebook</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd"></path>
        </svg>
    </a>
    <a href="#" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">Instagram</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path>
        </svg>
    </a>
    <a href="#" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">Twitter</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
        </svg>
    </a>


</div>

<a href="#testimmony" class="p-2 text-black transition duration-1000 ease-in-out transform font-base text-opacity-90 hover:text-slate-500 focus:outline-none focus:shadow-none focus:text-mana md:my-0 hover:border-white">Testimmony</a>


**/
