import { useState } from "react";
import { useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { StatChart } from "./StatChart";

import { getServerSideProps } from "../db/database";


export default function Main({data,email}) {

  const [stats,setStats] = useState();
  const navigate = useNavigate();


  useEffect(()=>{

      getServerSideProps(data.username).then((res)=>{

             console.log("res--",res);
             setStats(res);
             console.log("stats--",stats);


      });

    },[]);
    console.log("stats--",stats);


  return (

<main class="ml-60 pt-16 max-h-screen overflow-auto">


<div class="px-6 py-8">
  <div class="max-w-4xl mx-auto">
    <div class="bg-white rounded-3xl p-8 mb-5">
      <h1 class="text-3xl font-bold mb-10">Analytics Overview</h1>
      <div class="flex items-center justify-between">

        <div class="flex flex-wrap items-center gap-x-2">
       
        <a target='_blank'
            rel='noopener noreferrer' href={"http://lkn.bio/"+data.username}  class="mt-4 inline-flex items-center justify-center h-9 px-3 rounded-xl border hover:border-gray-400 text-gray-800 hover:text-gray-900 transition">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="1em" height="1em">
        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
        </a>
        <a target='_blank'
            rel='noopener noreferrer' href={"http://lkn.bio/"+data.username}  type="button" class="  mt-4 inline-flex items-center justify-center h-9 px-5 rounded-xl bg-gray-900 text-gray-300 hover:text-white text-sm font-semibold transition">
          See your page
        </a>
          { data.ga ?
            <a href="https://analytics.google.com/" target="_blank" type="button" class="mt-4 inline-flex items-center justify-center h-9 px-5 rounded-xl bg-blue-900 text-white hover:text-white text-sm font-semibold transition">
                Google Analytics
            </a> :

            <a href="#"  type="button" class="mt-4  inline-flex items-center justify-center h-9 px-5  rounded-xl   text-sm font-semibold transition">
                Add Your Google Analytics Tracker ID For Advance Analytics
            </a>
          }

        </div>
      </div>

      <hr class="my-10"/>

      <div class=" flex flex-wrap justify-center">
      { stats &&   <div class="pstats">
          <h2 class="text-2xl font-bold mb-4">Pageview activity over time</h2>

          <div class="grid grid-cols-2 gap-4">
            <div class="col-span-2">
              <div class="p-4 bg-green-100 rounded-xl">
                <div class="font-bold text-xl text-gray-800 leading-none">{stats.year.results[0]}</div>
                <div class="mt-5">
                  <button type="button" class="inline-flex items-center justify-center py-2 px-3 rounded-xl bg-white text-gray-800 hover:text-green-500 text-sm font-semibold transition">
                  Yearly Visitor
                  </button>
                </div>
              </div>
            </div>
            <div class="p-4 bg-yellow-100 rounded-xl text-gray-800">
              <div class="font-bold text-2xl leading-none">{stats.day.results[0]}</div>
              <div class="mt-2">Daily visitor</div>
            </div>
            <div class="p-4 bg-yellow-100 rounded-xl text-gray-800">
              <div class="font-bold text-2xl leading-none">{stats.week.results[0]}</div>
              <div class="mt-2">weekly visitor</div>
            </div>
            <div class="col-span-2">
              <div class="p-4 bg-purple-100 rounded-xl text-gray-800">
                <div class="font-bold text-xl leading-none">Montly visitor</div>
                <div class="mt-2">{stats.month.results[0]}</div>
              </div>
            </div>
          </div>
        </div> }

        <div class="stats" >
          <h2 class="text-2xl font-bold mb-4"></h2>

         { stats && <StatChart  data={stats} />}


        </div>
      </div>
    </div>
  </div>
</div>
</main>);

}


/**

<div class="space-y-4">
  <div class="p-4 bg-white border rounded-xl text-gray-800 space-y-2">
    <div class="flex justify-between">
      <div class="text-gray-400 text-xs">Number 10</div>
      <div class="text-gray-400 text-xs">4h</div>
    </div>
    <a href="javascript:void(0)" class="font-bold hover:text-yellow-800 hover:underline">Blog and social posts</a>
    <div class="text-sm text-gray-600">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="text-gray-800 inline align-middle mr-1" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
      </svg>Deadline is today
    </div>
  </div>
  <div class="p-4 bg-white border rounded-xl text-gray-800 space-y-2">
    <div class="flex justify-between">
      <div class="text-gray-400 text-xs">Grace Aroma</div>
      <div class="text-gray-400 text-xs">7d</div>
    </div>
    <a href="javascript:void(0)" class="font-bold hover:text-yellow-800 hover:underline">New campaign review</a>
    <div class="text-sm text-gray-600">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="text-gray-800 inline align-middle mr-1" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
      </svg>New feedback
    </div>
  </div>
  <div class="p-4 bg-white border rounded-xl text-gray-800 space-y-2">
    <div class="flex justify-between">
      <div class="text-gray-400 text-xs">Petz App</div>
      <div class="text-gray-400 text-xs">2h</div>
    </div>
    <a href="javascript:void(0)" class="font-bold hover:text-yellow-800 hover:underline">Cross-platform and browser QA</a>
  </div>

</div>

***/
