import React from 'react';


import "./styles.css";
import CheckBoxForm from "./CheckBoxForm";
import { useState , ref } from "react";
import { useEffect } from "react";
import {useLocation} from 'react-router-dom';
import { uploadPicture } from "./db/storage";
import { writeUserData } from "./db/database";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { checkIfUsernameExist } from "./db/database";
import {auth} from "./db/firebase";
import { Button } from 'reactstrap';

import {loadStripe} from '@stripe/stripe-js';





export default function CardForm({ isEdit, data }) {







  const [unlim, setUnlim] = useState([]);

   const [isok, setIsok] = useState(false);

  const [file, setFile] = useState();
  const location = useLocation();
  const [imgurl,setImgurl] = useState();
  const [isLoading,setIsLoading] = useState();
  const [errormsg,setErrormsg] =  useState({
    name: '',
    job: '',
    location: '',
    bio: '',
    email: '',
    website: '',
    imgurl:'',
    username: ''
  });
  const [coupon, setCoupon] = useState("");


    const navigate = useNavigate();
    let  d  = null;

    if(location.state!=null){
        d = location.state.email;
    }else if(auth.currentUser!=null){
        const user = auth.currentUser;
         d= user.email;
         console.log("user auth--",user);

    } else {

    }

  useEffect( () => {


        uploadPicture(file,d,setImgurl,setIsLoading);
        console.log("d--",d);
        console.log("imgurl--",imgurl);




  },[file]);

  useEffect( () => {



        console.log("imgurl--",imgurl);
        console.log("isLoading--",isLoading);





  },[imgurl,isLoading]);

   function handleChange(event) {

   event.preventDefault();

   setFile(event.target.files[0]);

    }

  const [inputNameValue, setInputNameValue] = useState("");
  const [inputJobValue, setInputJobValue] = useState("");
  const [inputWebsiteValue, setInputWebsiteValue] = useState("");
  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputLocationValue, setInputLocationValue] = useState("");
  const [inputBioValue, setInputBioValue] = useState("");
  const [inputUsernameValue, setInputUsernameValue] = useState("");
  const [inputBuyMeACoffeValue, setInputBuyMeACoffeValue] = useState("");
  const [inputGAValue, setInputGAValue] = useState("");
  const [inputCRYValue, setInputCRYValue] = useState("");
  const [free, setFree] = useState(false);





  const [inputCheckListValue, setInputCheckListValue] = useState({
    facebook: "",
    instagram: "",
    youtube: "",
    pinterest: "",
    snapchat: "",
    twitter: "",
    tiktok: "",
    reddit: "",
    linkedin: "",
    whatsApp: ""
   });

     console.log("form",d);
  if (isEdit) {
    console.log("is edit", data);
    useEffect(() => {
      if (data.name) setInputNameValue(data.name);
      console.log("name----", inputNameValue);

      if (data.job) setInputJobValue(data.job);

      if (data.email) setInputEmailValue(data.email);

      if (data.bio) setInputBioValue(data.bio);

      if (data.location) setInputLocationValue(data.location);

      if (data.website) setInputWebsiteValue(data.website);

      if (data.link) setInputCheckListValue(data.link);
      if (data.username) setInputUsernameValue(data.username);
      if (data.imgurl) setImgurl(data.imgurl);
      if (data.buymeacoffe) setInputBuyMeACoffeValue(data.buymeacoffe);
      if (data.ga) setInputGAValue(data.ga);

      if (data.unlim) setUnlim(data.unlim);
      if (data.crypto) setInputCRYValue(data.crypto);
      if (data.free) setFree(data.free);






    }, []); // em
  }


  function countWords(str) {
    return str?.split(' ')
        .filter(function(n) { return n != '' })
        .length ?? 0;
    }



    const handleCoupon = (event) => {
      setCoupon(event.target.value.trim());
      console.log("free---",free);

      if(event.target.value.trim()==="FREEFOREVER"){
         setFree(true);
        console.log("free---",free);
      }else {
        setFree(false);
      }

       setErrormsg({...errormsg});
    };

  const handleInputUsernameChange = (event) => {
    setInputUsernameValue(event.target.value.trim());
    errormsg.username="";
    setErrormsg({...errormsg});
  };
  const handleInputNameChange = (event) => {
    setInputNameValue(event.target.value);
  };
  const handleInputJobChange = (event) => {
    setInputJobValue(event.target.value);
  };
  const handleInputEmailChange = (event) => {
    setInputEmailValue(event.target.value.trim());
  };
  const handleBioChange = (event) => {
    setInputBioValue(event.target.value);
  };
  const handleInputLocationChange = (event) => {
    setInputLocationValue(event.target.value);
  };
  const handleInputWebsiteChange = (event) => {
    setInputWebsiteValue(event.target.value.trim());
  };

  const handleBuyMeACoffeChange = (event) => {
    setInputBuyMeACoffeValue(event.target.value.trim());
  };

  const handleGAChange = (event) => {
    setInputGAValue(event.target.value.trim());
  };

  const handleCRYChange = (event) => {
    setInputCRYValue(event.target.value.trim());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let err = false;
    if (inputNameValue.trim() === "") {
      // Input is empty

      errormsg.name="is empty!";

      err = true;
    }else{
      errormsg.name="";

    }
    if (inputJobValue.trim() === "") {
      // Input is empty

        errormsg.job="is empty!";
      err = true;
    }else{
        errormsg.job="";
    }
  /**  if (inputWebsiteValue.trim() === "") {
      // Input is empty

        errormsg.website="is empty!";
      err = true;
    }else{
         errormsg.website="";
    } **/
    if (inputEmailValue.trim() === "") {
      // Input is empty

        errormsg.email="is empty!";
      err = true;
    }else{
       errormsg.email="";
    }
    if (inputLocationValue.trim() === "") {
      // Input is empty

        errormsg.location="is empty!";
      err = true;
    }else{
         errormsg.location="";
    }

    console.log("imgurl---",imgurl);
   console.log("img---",imgurl);
    if (imgurl === "" || imgurl===undefined) {
      // Input is empty

        errormsg.imgurl="is empty!";

      err = true;
    }else{
       errormsg.imgurl="";
    }

    if (inputBioValue.trim() === "") {
      // Input is empty

        errormsg.bio="is empty!";
      err = true;
    }else{
      errormsg.bio=""
    }

    if (inputUsernameValue.trim() === "" ||   countWords( inputUsernameValue )>1 || inputUsernameValue.trim() === "dashboard") {
      // Input is empty

      if(inputUsernameValue.trim() === ""){
         errormsg.username="username is empty!";
    }else if (countWords( inputUsernameValue )>1){
       errormsg.username="Don't use space or special characters";
    }else if (inputUsernameValue.trim() === "dashboard"){
        errormsg.username="'dashboard' is not allowed";
    } else{

           errormsg.username="username is empty!";
    }

      err =true;
    }else{

         errormsg.username="";
    }
    console.log("err--", errormsg);
      setErrormsg((err)=>({...err}));

        if(err) return;
        console.log("Tor--", unlim);



    const signUpData =
        {
        name: inputNameValue,
        job: inputJobValue,
        location: inputLocationValue,
        bio: inputBioValue,
        email: inputEmailValue,
        website: inputWebsiteValue,
        imgurl:imgurl,
        link: inputCheckListValue,
        buymeacoffe:inputBuyMeACoffeValue,
        username: inputUsernameValue,
        free:free,
        layouts:data?.layouts ||  null,
        ga:inputGAValue,
        unlim:  unlim,
        crypto:inputCRYValue



    };

      console.log("signUpData", signUpData);


       if(isEdit){

         checkIfUsernameExist(inputUsernameValue.trim()).then((b)=>{
              if(b || inputUsernameValue.trim() == data.username ){



                writeUserData(d,signUpData);

                data = signUpData;

               //  navigate('/'+inputUsernameValue,{ state:{ data:data }  } );
              window.location.href =  "http://lkn.bio/"+data.username;

              } else{


                console.log("bool--",b);
                  errormsg.username="already taken";
                  setErrormsg({...errormsg});
                  console.log("err--",errormsg);

              }
               });

       }else{

         checkIfUsernameExist(inputUsernameValue.trim()).then((b)=>{
              if(b){

                writeUserData(d,signUpData).then(()=>{
                //  window.location.href = `https://buy.stripe.com/00g8yY0Qy4Aid32dQQ?prefilled_email=${d}`;
                // https://pay.boomfi.xyz/2jI3kNmxqwDEcS92Yqoy5gqhi0u
                // https://app.boomfi.xyz/customer
                if(free){
                window.location.href = "https://lkn.bio/dashboard";
              }else{
                window.location.href = `https://pay.boomfi.xyz/2jqO1xzlJtk3QeKfvmBCM9rKwjy?customer_ident=${d}&redirect_to=https://lkn.bio/dashboard`;

              }


                //    window.location.href = `https://lkn.lemonsqueezy.com/buy/9ca37b44-4166-4001-ab01-f96e95009b19`;

                });



              } else {


                console.log("bool--",b);
                  errormsg.username="already taken";
                  setErrormsg({...errormsg});
                  console.log("err--",errormsg);

              }
               });

       }






    // Input is not empty

  };






  return (



    <div class="wrapper" >

    <div class="cnac"> <a href="#" class="-m-1.5 p-1.5 logolk">

      { !isEdit && <svg class="h-11 w-auto" version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="559.000000pt" height="447.000000pt" viewBox="0 0 559.000000 447.000000"
       preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M3644 3850 c-51 -11 -107 -33 -144 -60 -25 -18 -149 -148 -289 -305
      -20 -22 -92 -102 -160 -178 -103 -114 -122 -141 -115 -157 13 -27 201 -200
      218 -200 8 0 88 82 179 183 191 212 305 323 353 343 78 33 141 13 234 -72 101
      -94 480 -514 486 -540 3 -13 3 -35 0 -48 -3 -13 -168 -203 -367 -422 -199
      -220 -486 -536 -638 -704 -307 -338 -359 -395 -446 -488 -33 -35 -64 -70 -68
      -78 -20 -36 -54 -9 -329 261 -154 151 -286 275 -293 275 -14 0 -86 -81 -129
      -144 -32 -48 -36 -125 -8 -171 17 -27 713 -716 731 -723 8 -3 94 86 237 243
      50 55 162 179 250 275 685 755 1242 1370 1359 1500 76 85 152 169 168 187 l30
      32 -269 277 c-455 468 -628 640 -675 667 -81 48 -212 67 -315 47z"/>
      <path d="M1740 3796 c-178 -52 -175 -49 -885 -858 -71 -82 -132 -148 -135
      -148 -4 0 -12 -11 -18 -24 -10 -23 6 -41 331 -358 188 -183 384 -373 435 -423
      l94 -90 50 55 c95 105 119 174 88 255 -6 17 -128 147 -276 295 -145 146 -264
      269 -264 274 0 19 340 418 464 544 102 104 145 132 207 132 91 0 93 -1 854
      -767 396 -398 725 -723 730 -723 13 1 136 154 152 189 18 38 16 77 -6 119 -28
      56 -1403 1418 -1473 1460 -113 67 -256 95 -348 68z"/>
      <path d="M2169 2323 c-167 -186 -342 -383 -389 -438 -46 -55 -124 -145 -172
      -200 -48 -55 -88 -104 -88 -109 0 -5 51 -60 113 -123 l112 -114 90 103 c50 56
      128 145 175 198 299 338 588 668 606 692 57 76 39 154 -59 255 -39 40 -74 73
      -78 73 -4 0 -143 -152 -310 -337z"/>
      </g>
      </svg> }
    { !isEdit &&  <span>Lkn Bio</span> }

    </a> <h1> { !isEdit ? 'Create an account' : 'Setup your page' } </h1> </div>

      <form id="frm" class="card-form" onSubmit={handleSubmit} >



        <div class="card-form__inner">



          <div class="card-input">
            <label for="name" class="card-input__label">
              Full Name <span class="errcol">{errormsg.name}</span>
            </label>
            <input

              type="text"
              id="name"
              class="card-input__input"
              autocomplete="off"
              value={inputNameValue}
              onChange={handleInputNameChange}

            />
          </div>

           <div id="#payment-element"></div>
          <div class="card-input">
            <label for="job" class="card-input__label">
              Job <span class="errcol">{errormsg.job}</span>
            </label>
            <input

              type="text"
              id="job"
              class="card-input__input"
              autocomplete="off"
              value={inputJobValue}
              onChange={handleInputJobChange}

            />
          </div>

          <div class="card-input">
            <label for="location" class="card-input__label">
              Location <span class="errcol">{errormsg.location}</span>
            </label>
            <input
              type="text"
              id="location"
              class="card-input__input"
              autocomplete="off"
              value={inputLocationValue}
              onChange={handleInputLocationChange}
            />
          </div>

          <div class="card-input">
            <label for="bio" class="card-input__label">
              Bio <span class="errcol">{errormsg.bio}</span>
            </label>
            <textarea
              type="text"
              id="bio"
              class="card-input__input"
              autocomplete="off"
              value={inputBioValue}
              onChange={handleBioChange}
            ></textarea>
          </div>

          <div class="card-input">
            <label for="email" class="card-input__label">
              Email <span class="errcol">{errormsg.email}</span>
            </label>
            <input
              placeholder="Professional Email or Business Email"
              type="email"
              id="email"

              class="card-input__input"
              autocomplete="off"
              value={inputEmailValue}
              onChange={handleInputEmailChange}
            />
          </div>

          <div class="card-input">
            <label for="username" class="card-input__label">
              Username: linktree/{inputUsernameValue} <span class="errcol">{errormsg.username}</span>
            </label>
            <input
              placeholder=""
              type="text"
              id="username"

              class="card-input__input"
              autocomplete="off"
              value={inputUsernameValue}
              onChange={handleInputUsernameChange}
            />
          </div>

          <div class="card-input">
            <label for="website" class="card-input__label">
              Website <span class="errcol"> {errormsg.website}</span>
            </label>
            <input

              placeholder="optional"
              id="website"
              class="card-input__input"
              value={inputWebsiteValue}
              onChange={handleInputWebsiteChange}


            />
          </div>

          <div class="card-input">
            <label for="location" class="card-input__label">
              Support And Funding Link <span class="errcol"></span>
            </label>
            <input

              placeholder="optional"
              id="bmc"
              class="card-input__input"
              autocomplete="off"
              value={inputBuyMeACoffeValue}
              onChange={handleBuyMeACoffeChange}

            />
          </div>


          <div class="card-input">
            <label for="email" class="card-input__label">
              Crypto Wallet  <span class="errcol"></span>
            </label>
            <input
              placeholder="Optional"
              type="text"
              id="ga"

              class="card-input__input"
              autocomplete="off"
              value={inputCRYValue}
              onChange={handleCRYChange}
            />
          </div>

          <div class="card-input">
            <label for="email" class="card-input__label">
              Google Analytics  <span class="errcol"></span>
            </label>
            <input
              placeholder="Tracking ID (Optional)"
              type="text"
              id="ga"

              class="card-input__input"
              autocomplete="off"
              value={inputGAValue}
              onChange={handleGAChange}
            />
          </div>


          <div class="card-input">
            <label for="email" class="card-input__label">
              Coupon Code  <span class="errcol"></span>
            </label>
            <input
              placeholder="Coupon Code (Optional)"
              type="text"
              id=""

              class="card-input__input"
              autocomplete="off"
              value={coupon}
              onChange={handleCoupon}
            />
          </div>



        <div class="card-input">
            <label for="profile" class="card-input__label">
              Upload Front Page Picture <span class="errcol">{errormsg.imgurl}</span>
            </label>
            <input type="file" onChange={handleChange} />
              { isLoading && (
                            <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-black"
                  role="status">
                  <span
                    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                  >
              </div> )}

          </div>


          <div class="card-input">
            <label for="socialmedia" class="card-input__label">
            Select Your Social Media
            </label>

            <CheckBoxForm unlimdata={unlim} setunlimdata={setUnlim} isEdit={isEdit}  checkList={inputCheckListValue} />
          </div>

          <div class="card-input">
            <label for="website" class="card-input__label">

            </label>

          </div>


          <button class="stripeButton"


          class="card-form__button"
          >

            { isEdit ? 'Submit' : 'Start Your Free Trial' }

          </button>



        </div>

      </form>
    </div>




  );
}
