import React from 'react';

import { getFirestore } from "firebase/firestore";
import { collection, addDoc ,doc,getDoc,getDocs, query, where ,setDoc,deleteDoc, updateDoc} from "firebase/firestore";
import { db } from "./firebase";

import moment from 'moment';


import posthog from 'posthog-js';



const testStatsApi = async ()=>{

  const data = JSON.stringify(
    {"query": {"kind": "HogQLQuery", "query": "SELECT * FROM events  "}}
                   //"SELECT *  FROM events WHERE event = '$pageview' GROUP BT toDate("+date+") ";
 );
    // AND timestamp > toDateTime('"+dateFrom+"')
//params.append('display', 'BoldNumber');
 // Make fetch request
//const trendsUrl = `https://app.posthog.com/api/projects/61512/insights/trend?${trendsParams}`;
const query = `https://app.posthog.com/api/projects/61512/query/`;
//const url = `"https://us.posthog.com"/api/projects/61512/insights/trend?${params}`;
//const eurl = `https://app.posthog.com/api/event/?personal_api_key=phx_IPVx0Zfh0pznQWS6tqNJM0tOOCb36L2xxMTpa7M8Kiv/${params}`;
const request = await fetch(query, {
 method: 'POST',
 headers: {
   'Content-Type': 'application/json',
   'Authorization': `Bearer phx_IPVx0Zfh0pznQWS6tqNJM0tOOCb36L2xxMTpa7M8Kiv`
 },
 body:data
});

return request.json();


}

const fetchStatsApi = async (t,user)=>{

  console.log("REQ TEST--",t,user);

  const data = JSON.stringify(
    {"query": {"kind": "HogQLQuery", "query": "SELECT COUNT(event) FROM events WHERE event = '"+user+"' AND timestamp > toDateTime('"+t+"')  "}}
                   //"SELECT *  FROM events WHERE event = '$pageview' GROUP BT toDate("+date+") ";
 );
    // AND timestamp > toDateTime('"+dateFrom+"')
//params.append('display', 'BoldNumber');
 // Make fetch request
//const trendsUrl = `https://app.posthog.com/api/projects/61512/insights/trend?${trendsParams}`;
const query = `https://app.posthog.com/api/projects/61512/query/`;
//const url = `"https://us.posthog.com"/api/projects/61512/insights/trend?${params}`;
//const eurl = `https://app.posthog.com/api/event/?personal_api_key=phx_IPVx0Zfh0pznQWS6tqNJM0tOOCb36L2xxMTpa7M8Kiv/${params}`;
const request = await fetch(query, {
 method: 'POST',
 headers: {
   'Content-Type': 'application/json',
   'Authorization': `Bearer phx_IPVx0Zfh0pznQWS6tqNJM0tOOCb36L2xxMTpa7M8Kiv`
 },
 body:data
});

return request.json();


}
  // pages/insights.js

export async function getServerSideProps(user) {
    // Get filter value
//  const filterValue = context.query.filter
    // Set up params
    const date = Date.now();
    const filterValue = "PostHog";
    const params = new URLSearchParams();

    const day = moment().subtract(5,'h').toISOString();
    const week = moment().subtract(7,'d').toISOString();
    const month = moment().subtract(31,'d').toISOString();
    const year = moment().subtract(365,'d').toISOString();

    const sday = await fetchStatsApi(day,user);
    const sweek = await fetchStatsApi(week,user);
    const smonth = await fetchStatsApi(month,user);
    const syear = await fetchStatsApi(year,user);

    console.log("day---"+sday);
    console.log("week---"+sweek);
    console.log("month---"+smonth);
    console.log("year---"+syear);

    return {day:sday,week:sweek,month:smonth,year:syear};

    // Return response as prop phx_IPVx0Zfh0pznQWS6tqNJM0tOOCb36L2xxMTpa7M8Kiv

}




export const writeUserData = async (email,data) => {

  try {


      const req = doc(db, "users", email);
        await setDoc(req,data);
 

    } catch (e) {

    console.log("Error adding document: ", e);

     }


};

export const updateUserData = async (email,data) => {

  try {


        const req = doc(db, "users", email);
        await updateDoc(req,data);

    } catch (e) {

    console.log("Error adding document: ", e);

  };

}

export const deleteUserData = async (email,data) => {

  try {


      await deleteDoc( doc(db, "users", email) );

    } catch (e) {

    console.log("Error adding document: ", e);

  };

}

export const checkUserSignUp = async (email) => {

  const docRef = doc(db, "users", email);

  const docSnap = await getDoc(docRef);
    console.log("log in-----",docRef);

   if (docSnap.exists()) {
     return true;
    } else {
// docSnap.data() will be undefined in this case
      return false;

      }

};

export const readDB = async (email) => {

  const docRef = doc(db, "users", email);
  const docSnap = await getDoc(docRef);

   if (docSnap.exists()) {
     console.log("Document data:", docSnap.data());
     return docSnap.data();
    } else {
// docSnap.data() will be undefined in this case
       return null;
      }

}



export const readProfileDB = async (path) => {

  const q = query(collection(db, "users"), where("username", "==", path));

  const querySnapshot = await getDocs(q);

   if (querySnapshot.empty) {
    // console.log("Document data empty:",querySnapshot);

     return null;
    } else {
// docSnap.data() will be undefined in this case

            // return state;

           return querySnapshot.docs[0].data();
        //   console.log("state ---",accessGlobalState().get());

      }

}



export const checkIfUsernameExist = async (user) => {

    const q = query(collection(db, "users"), where("username", "==", user));

    const querySnapshot = await getDocs(q);
    console.log("querySnapshot--",querySnapshot.empty);
    if (querySnapshot.empty) {

        return true;

     } else {
 // docSnap.data() will be undefined in this case
       return false;

       }


}



export const sendContact = async (data) => {

  try {
  const docRef = await addDoc(collection(db, "message"), data);
  console.log("Document written with ID: ", docRef.id);
    } catch (e) {
  console.error("Error adding document: ", e);
                }


};
