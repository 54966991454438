import { getStorage, getDownloadURL , ref, uploadBytes } from "firebase/storage";

const filename = function(f){
  console.log("file---",f);

  if(f && f.name != undefined){

      var fileExt = f.name.split('.').pop();
       console.log("fileExt---",f.name);

       if(["jpeg","jpg","png"].includes(fileExt) ){
          return fileExt;
              }
             else {return null;}

                             }
}

export const uploadPicture =  (file,email,s,lding)  =>  {

    if (!file) return;
  var fileExt = filename(file);
  console.log("filex---",email,fileExt);
   const storage = getStorage();

  const storageRef = ref(storage, `${email}/pic.${fileExt}`);

// 'file' comes from the Blob or File API
  let imgurl = "";
    lding(true);
    uploadBytes(storageRef, file).then( (snapshot) => {

     getDownloadURL(ref(storage, `${email}/pic.${fileExt}`))
      .then((url) => {
  // `url` is the download URL for 'images/stars.jpg'

  // This can be downloaded directly:
          imgurl = url;
            s(url);
            lding(false);
          console.log("storage---",url);


                })
                  .catch((error) => {



                      });


      });

      return imgurl;




};
