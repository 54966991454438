import { initializeApp } from "firebase/app";
import { getAnalytics,isSupported } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
 apiKey: "AIzaSyCECbGhFEMtmA3Ikm9M-NcZyJZMlfS2APU",
 authDomain: "linktree-487da.firebaseapp.com",
 projectId: "linktree-487da",
 storageBucket: "linktree-487da.appspot.com",
 messagingSenderId: "867909297809",
 appId: "1:867909297809:web:397c44d177fe6a57a44130",
 measurementId: "G-WY0YF7SP5C",
 databaseURL: "https://linktree-487da-default-rtdb.firebaseio.com/",
 storageBucket: "gs://linktree-487da.appspot.com"


};let auth;
let db;


if(typeof window != undefined){


// Initialize Firebase
const app = initializeApp(firebaseConfig);

auth = getAuth(app);
db = getFirestore(app);

const analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);
const storage = getStorage();

}


export {auth, db} ;
