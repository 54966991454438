import { useState } from "react";
import { useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { StatChart } from "./StatChart";
import  CardForm  from "../CardForm"
import { getServerSideProps } from "../db/database";


export default function Profile({data}) {

  const [stats,setStats] = useState();
  const navigate = useNavigate();


  useEffect(()=>{



    },[]);
    console.log("stats--",stats);


  return (

<main class="ml-60 pt-16 max-h-screen overflow-auto">
<div class="px-6 py-8">
  <div class="max-w-4xl mx-auto">
    <CardForm data={data} isEdit={true} />
  </div>
</div>
</main>);

}
