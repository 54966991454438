import { useState } from "react";
import { useEffect } from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom';

export default function Header({ section, toggle,email,data }) {
  const navigate = useNavigate();

  return (
    <header className="nheader  justify-center py-3 px-4 h-16">


<ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
    <li className="me-2 ">
        <a   onClick={ ()=> {section( false,true, false, false,false );} } className={` ${  toggle.stats && 'bg-yellow-300' } asidebtn font-bold text-black inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white `}>Stats</a>
    </li>
    <li className="me-2">
        <a   onClick={ ()=>{section( true,false, false, false ,false )} }  className={`${  toggle.profile && 'bg-yellow-300' } asidebtn font-bold text-black inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white`} >Profile</a>
    </li>
    <li className="me-2">
        <a   onClick={ ()=>{

            section( false, false, false,false,true )
            if(email)
            navigate("/edit",{ state: {data:data,email:email}  });
          } }

       className={` ${  toggle.editpage && 'bg-yellow-300' } asidebtn font-bold text-black inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white `}>Edit Page</a>
    </li>
    <li className="me-2">
        <a   onClick={ ()=>{section( false, false, true, false,false  ) }} className={` ${  toggle.suscription && 'bg-yellow-300' } asidebtn font-bold text-black inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white `}>Suscription</a>
    </li>


    <li className="me-2">
        <a   onClick={ ()=>{section( false, false, false, true,false  )} } className={` ${  toggle.support && 'bg-yellow-300' } asidebtn font-bold text-black inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white `}>Support</a>
    </li>


</ul>

    </header>
  );
}
