import React from "react";

export default function Terms() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>

      <a
        className="text-base leading-6  hover:text-gray-900"
        type="button"
        onClick={() => setShowModal(true)}
      >
      Terms
      </a>
      {showModal ? (
        <>
          <div
            className="inline overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex   justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-black">
                    Terms of Service
                </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative overflow-y-auto p-4">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                  1. Acceptance

By accessing or using lkn.bio website and services (the "Service"), you agree to be bound by these Terms of Service (the "Terms"). If you disagree with any part of the Terms, then you do not have the right to access or use the Service.
<br/>
2. Use of the Service

The Service allows you to create and manage a "link in bio" page. You are responsible for all content uploaded, posted, or otherwise transmitted through your lkn.bio page ("User Content").
<br/>
3. User Content

You represent and warrant that you have all rights and permissions necessary to upload User Content and grant the rights and licenses set forth in these Terms. You further agree not to upload User Content that is:

Illegal, obscene, defamatory, threatening, or hateful.
Infringes on the intellectual property rights of any third party.
Contains viruses or other malicious code.
lkn.bio reserves the right, at its sole discretion, to remove any User Content that violates these Terms or that we otherwise deem inappropriate.
<br/>
4. Third-Party Links and Services

The Service may contain links to third-party websites, advertisers, services, or other events or activities that are not owned or controlled by lkn.bio. We do not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services.

If you access any third-party website or service from the Service, you do so at your own risk. You agree that lkn.bio will not be liable for any damage or loss arising from your use of or access to any third-party website or service.
<br/>
5. Disclaimer

The Service is provided on an "as is" and "as available" basis. lkn.bio makes no warranties, express or implied, regarding the operation or performance of the Service.
<br/>
6. Limitation of Liability

lkn.bio will not be liable for any damages arising out of or related to your use of the Service, including but not limited to direct, indirect, incidental, consequential, or punitive damages.
<br/>
7. Termination

lkn.bio may terminate your access to the Service at any time, for any reason, or no reason at all, with or without notice.
<br/>
8. Changes to the Terms

lkn.bio reserves the right to update these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after the posting of the revised Terms means that you accept and agree to the changes.
<br/>
9. Governing Law

These Terms shall be governed by and construed by the laws of US, without regard to its conflict of law provisions.
<br/>
10. Dispute Resolution

Any dispute arising out of or relating to these Terms will be resolved by binding arbitration by the rules of the American Arbitration Association. .
<br/>
11. Entire Agreement

These Terms constitute the entire agreement between you and lkn.bio regarding your use of the Service.

                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>

                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
